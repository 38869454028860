const SURCHARGE = {
  BILLING_DUTIES_ADDED:
    '<strong>$20.00</strong> surcharge added for billing <br/> Duties/Taxes back to your account! <br/> You can avoid this surcharge by <br/> billing Duties/Taxes to the receiver.',
  BILLING_DUTIES_REMOVED:
    '<strong>$20.00</strong> surcharge removed for billing <br/> Duties/Taxes back to your account!',

  NDS_ADDED: '<strong>$20.00</strong> Neutral Delivery Service <br/> surcharge added!',
  NDS_REMOVED: '<strong>$20.00</strong> Neutral Delivery Service <br/> surcharge removed!',

  PRICE_UPDATED: 'Price updated!',

  PICKUP_REMOVED: 'Pickup fee removed!',

  MEASUREMENT_SYSTEM_UPDATED: 'Measurement System changed, price may have updated!',

  RECEIVERS_RESIDENTIAL_ADDED: '<strong>$6.55</strong> Receivers Residential Address <br/> surcharge added!',
  RECEIVERS_RESIDENTIAL_REMOVED: '<strong>$6.55</strong> Receivers Residential Address <br/> surcharge removed!'
};

export default SURCHARGE;
