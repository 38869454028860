import React from 'react';
import styled from 'styled-components/macro';

import config from 'config';

import ActionLink from 'components/common/ActionLink';

import {secondaryFont} from 'styles/shared';

const StyledList = styled.ul`
  padding-inline-start: 3rem;
  ${secondaryFont};

  > li {
    margin-top: 1.7rem;
  }
`;

const StyledActionLink = styled(ActionLink)`
  ${secondaryFont};
`;

function DomesticDisclaimer() {
  function openLink() {
    window.open(config.extendedAreaLocationUrl, '_blank');
  }

  return (
    <StyledList>
      <li>
        Quotes are based on the weights and dimensions provided by the client. Final shipping charges are based on UPS’s
        weight and measurement of packages shipped. Additional surcharges may apply.
      </li>
      <li>
        A pickup fee does apply to domestic shipments. There is no extra charge for delivery unless a delivery is to a
        residential address ($6.10). Or if a delivery is to an{' '}
        <StyledActionLink title="Extended Area location" onClick={openLink} />. This fee may not be reflected in your
        quote. Please check the UPS site to see if the shipment is to an extended area.
      </li>
      <li>
        Domestic dimensional weight formula: (Length x Width x Height) / 166.00. The heavier of actual weight or
        dimensional weight is used to calculate shipping charges.
      </li>
      <li>If shipping Dangerous Goods or Alcohol, please contact customer service at 972-383-9901.</li>
      <li>
        Packages with an actual weight of more than 150 pounds (70 kg), or that exceed 108 inches (274 cm) in length or
        a total of 157 inches (400 cm) in length and girth combined, as measured to determine their billable weight, are
        not accepted for transportation. These packages are subject to an Over Maximum Limits surcharge ($875), in
        addition to all other applicable charges, if found in the UPS small package system.
      </li>
      <li>
        A Large Package Surcharge will be applied to each UPS package when its length plus girth [(2 x width) + (2 x
        height)] combined exceeds 118 inches, but does not exceed the maximum UPS size of 157 inches.
      </li>
      <li>
        Large Packages are subject to a minimum billable weight of 90 pounds in addition to the Large Package Surcharge.
      </li>
    </StyledList>
  );
}

export default DomesticDisclaimer;
